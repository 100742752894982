import React, { useContext, useState } from 'react';
import {
    assignDeptPermission,
    deleteInvitation,
    Invitation,
    removeDeptPermission,
} from '../../services/apiService';
import DeleteInvite from './DeleteInvite';
import ExtendedInfoComponent from './ExtendedUserInfo';
import SendReminders from './SendReminders';
import styles from './UserRow.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import Select from '../general-components/Select';
export interface UserListInfo {
    id: string;
    email: string;
    isAdmin?: string;
    name?: string;
    role?: string;
    expiryDate?: string;
    deptPermissions?: any[];
    activity?: any[];
}

const UserRow: React.FC<{
    userInfo: UserListInfo;
    isInvite: boolean;
    invite?: Invitation;
    onChange?: (obj: any, remove?: boolean) => void;
    deptLicenses: number;
}> = ({ userInfo, isInvite, invite, onChange, deptLicenses }) => {
    const userIsActive = (user: any) => {
        const expDate = new Date(user.expiryDate);
        const now = new Date();

        return expDate > now;
    };

    const [user, setUser] = useState(userInfo);

    const handleChange = (u: any) => {
        setUser(u);
        onChange(u);
    };

    const [permissions, setPermissions] = React.useState<any[]>(
        user.deptPermissions ?? []
    );

    const permLib: { [key: string]: string } = {
        'Can Approve Implants': 'Approve',
        'Can Manage Protocols': 'Manage Protocols',
        'Can Manage Folders': 'Manage Groups',
    };

    const getPermKeyFromValue = (value: string) => {
        return Object.keys(permLib).find((key) => permLib[key] === value);
    };

    const updatePermissions = async (p: any) => {
        const perm = permLib[p];
        const permExists = permissions.includes(perm);
        let success = false;
        if (permExists) {
            success = await removeDeptPermission(user.id, perm);
        } else {
            success = await assignDeptPermission(user.id, perm);
        }
        if (success) {
            setPermissions(
                permExists
                    ? permissions.filter((x) => x !== perm)
                    : [...permissions, perm]
            );
        }
    };

    const isActiveClass = (user: any) => {
        if (userIsActive(user)) {
            return styles['active-user'];
        } else {
            return styles['inactive-user'];
        }
    };

    const deleteInvite = async () => {
        const res = await deleteInvitation(invite._id);

        if (res) {
            onChange(invite, true);
        }
    };

    return (
        <React.Fragment key={'frag' + user.id}>
            <tr
                id={user.id}
                className={
                    !isInvite
                        ? `${styles.clickable}  ${isActiveClass(user)}`
                        : ''
                }
            >
                {!isInvite && (
                    <td className={`${styles['user-status']}`}>
                        <span>
                            {userIsActive(user) ? 'Active' : 'Inactive'}
                        </span>
                        Searches:
                        <br />
                        {user.activity}/week
                    </td>
                )}
                {user.name && <td>{user.name}</td>}
                <td>{user.email}</td>
                {user.role && <td>{user.role}</td>}
                {isInvite && invite.isAdmin && <td>Admin</td>}
                {!isInvite && (
                    <>
                        <td>
                            <Select
                                defaultChecked={permissions.map((p) =>
                                    getPermKeyFromValue(p)
                                )}
                                type="checkbox"
                                name="permissions"
                                options={Object.keys(permLib)}
                                changeSelected={updatePermissions}
                            ></Select>
                        </td>
                    </>
                )}
                {!isInvite && (
                    <ExtendedInfoComponent
                        isActive={userIsActive}
                        onChange={handleChange}
                        user={user}
                        deptLicenses={deptLicenses}
                    />
                )}
                {isInvite && !invite.isAdmin && <td></td>}

                {isInvite && (
                    <td className={styles['invite-delete']}>
                        <SendReminders
                            infos={[{ email: user.email, id: user.id }]}
                        >
                            <FontAwesomeIcon icon={faBell} />
                        </SendReminders>
                        <DeleteInvite
                            onDelete={deleteInvite}
                            id={user.id}
                            email={user.email}
                        />
                    </td>
                )}
            </tr>
        </React.Fragment>
    );
};

export default UserRow;
