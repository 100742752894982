import React, { useContext, useRef } from 'react';
import { useEffect, useState } from 'react';
import SETTING_KEYS from '../../constants/setting-keys';
import {
    getAllFieldstrength,
    getAllManufactures,
    getAllTypes,
    updateImplant,
    addManufacturer,
    addImplant,
    draftImplant,
    uploadImplantImage,
    extractDataFromPDF,
    uploadSourceFile,
    completeQueuedImplant,
    getGUDIDData,
} from '../../services/apiService';
import Button, { ButtonType } from '../general-components/Button';
import SelectItem from '../general-components/SelectItem';
import AuthContext from '../../store/auth-context';
import RIGHTS_KEYS from '../../constants/rights-keys';
import LoadingRing from '../ui-components/LoadingRing';
import DragAndDrop from '../general-components/drag-and-drop';
import SelectSource from './SelectSource';
import { useImplantContext } from '../../store/implant-context';
import Modal from '../general-components/Modal';
import TypeSearchField from './TypeSearchField';
import Alert from '../general-components/Alert';
import styles from './EditImplant.module.css';
import {
    AutofilledFieldsType,
    AutofilledFieldsKeys,
} from '../../utils/pdfExtractor';
import { normalizeManufacturer } from '../../utils/utils';
import CatalogNumbers from './EditCatalogNumbers';
import Conditional from '../conditionals/Conditional';
import CustomFields from '../custom-fields/CustomFields';
import AdditionalInfo from './AdditionalInfo';
import ImplantImage from './ImplantImage';
import ImplantSource from './ImplantSource';
import GeneralInfo from './GeneralInfo';
import FormInput from '../general-components/FormInput';
import Brand from './Brand';
import Region from './Region';
import Model from './Model';
import Category from './Category';
import MriStatus from './MriStatus';

const regions = ['UK', 'US', 'EU', 'Worldwide'];

const settings = [
    SETTING_KEYS.waitingPeriod,
    SETTING_KEYS.fieldStrength,
    SETTING_KEYS.mriArc,
    SETTING_KEYS.prep,
    SETTING_KEYS.slewRate,
    SETTING_KEYS.brms,
    SETTING_KEYS.coilType,
    SETTING_KEYS.spatialGradient,
    SETTING_KEYS.sar,
    SETTING_KEYS.artefacts,
    SETTING_KEYS.temp,
];

const EditImplant: React.FC<{
    id?: string;
    isNew: boolean;
    onSubmit?: Function;
}> = ({ id, isNew, onSubmit }) => {
    const userContext = useContext(AuthContext);
    const {
        dispatchConditionals,
        dispatchInfo,
        fileName,
        conditionals,
        info,
        file,
        runPDFExtractor,
        usesExistingFile,
        setUsesExistingFile,
    } = useImplantContext();

    const [imageName, setImageName] = useState(info[SETTING_KEYS.image]);
    const [manufacturers, setManufacturers] = useState([]);
    const [fieldStrengths, setFieldStrengths] = useState([]);
    const [image, setImage] = useState();
    const [types, setTypes] = useState<string[]>([]);
    const [showWarningIcon, setShowWarningIcon] = useState(false);
    const [isEnterCustomManufacturer, setIsEnterCustomManufacturer] =
        useState(false);

    const [uploadStatus, setUploadStatus] = useState<
        | 'initial'
        | 'source-missing'
        | 'source-uploading'
        | 'source-done'
        | 'source-error'
    >('initial');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isExtracting, setIsExtracting] = useState(false);
    const [isFetchingGudid, setIsFetchingGudid] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [isError, setIsError] = useState(false);
    const [newImplantError, setNewImplantError] = useState(false);
    const [errorCond, setErrorCond] = useState('');
    const [extractionError, setExtractionError] = useState<string | null>(null);
    const [fileError, setFileError] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [autofilledFields, setAutofilledFields] =
        useState<AutofilledFieldsType>({});
    const [returnedError, setReturnedError] = useState('');
    const [showSavedMessage, setShowSavedMessage] = useState(false);
    const [notifyApprovers, setNotifyApprovers] = useState(false);

    const getData = async () => {
        const types = await getAllTypes();
        const manufactures = await getAllManufactures();
        const fieldStrengths = await getAllFieldstrength();
        setTypes(types);
        setManufacturers(manufactures);
        setFieldStrengths(fieldStrengths);
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    const findMatchingManufacturer = (
        normalizedExternalName: string,
        manufacturers: { name: string }[]
    ): string | null => {
        for (const manufacturerObj of manufacturers) {
            const manufacturerName = manufacturerObj.name;
            const normalizedDbName = normalizeManufacturer(manufacturerName);
            if (normalizedDbName === normalizedExternalName) {
                return manufacturerName;
            }
        }
        return null;
    };

    const findMatchingType = (
        externalTypeName: string,
        types: string[]
    ): string | null => {
        const normalizedExternalType = externalTypeName.toLowerCase();

        for (const typeName of types) {
            if (typeName.toLowerCase() === normalizedExternalType) {
                return typeName;
            }
        }

        return null;
    };

    const handleFetchGUDIDData = async () => {
        setIsFetchingGudid(true);

        try {
            const gudidInfo = await getGUDIDData(
                info[SETTING_KEYS.gudidDeviceId]
            );

            updateInfoField(
                SETTING_KEYS.catalogNumbers,
                gudidInfo.catalogNumber
            );
            updateInfoField(
                SETTING_KEYS.gmdnDefinition,
                gudidInfo.gmdnTerms.gmdn[0].gmdnPTDefinition
            );
            updateInfoField(
                SETTING_KEYS.distEndDate,
                gudidInfo.deviceCommDistributionEndDate
            );

            const type =
                gudidInfo.productCodes.fdaProductCode[0].productCodeName;
            const matchedType = findMatchingType(type, types);
            updateInfoField(SETTING_KEYS.type, matchedType || type);

            const normalizedManufacturer = normalizeManufacturer(
                gudidInfo.companyName
            );
            const matchedManufacturer = findMatchingManufacturer(
                normalizedManufacturer,
                manufacturers
            );
            if (matchedManufacturer) {
                updateInfoField(SETTING_KEYS.manufacturer, matchedManufacturer);
            } else {
                setIsEnterCustomManufacturer(true);
                updateInfoField(
                    SETTING_KEYS.manufacturer,
                    gudidInfo.companyName
                );
            }
        } catch (error: any) {
            setErrorCond(error);
        } finally {
            setIsFetchingGudid(false);
        }
    };

    const handleRegionChange = (region: string) => {
        const currentRegions = info[SETTING_KEYS.region] || [];

        let updatedRegions;

        if (currentRegions.includes(region)) {
            updatedRegions = currentRegions.filter((r: string) => r !== region);
        } else {
            updatedRegions = [...currentRegions, region];
        }
        updateInfoField(SETTING_KEYS.region, updatedRegions);
    };

    const handleActivePassiveChange = (value: string) => {
        updateInfoField(SETTING_KEYS.category, value);
    };

    useEffect(() => {
        const uploadFile = async () => {
            if (file) {
                try {
                    setUploadStatus('source-uploading');
                    const succeeded = await uploadSourceFile(file);
                    if (succeeded) {
                        setUploadStatus('source-done');
                    } else {
                        setUploadStatus('source-error');
                    }
                } catch (error) {
                    setFileError(true);
                }
            }
        };

        uploadFile();
    }, [file]);

    useEffect(() => {
        const extractData = async () => {
            if (fileName && runPDFExtractor) {
                setIsExtracting(true);
                try {
                    const response = await extractDataFromPDF(fileName);

                    if (response.error) {
                        setExtractionError(response.error);
                    } else {
                        const processedData = response.data;
                        console.log(processedData);

                        if (processedData.mriStatus !== undefined) {
                            updateInfoField(
                                SETTING_KEYS.mriStatus,
                                processedData.mriStatus
                            );
                        }

                        setAutofilledFields((prev) => ({
                            ...prev,
                            status: true,
                        }));

                        const bothEchoesReturned =
                            processedData.conditionals.artifact.some(
                                (artifact) =>
                                    artifact.pulseSequence ===
                                    'Both gradient and spin echo were returned'
                            );

                        setShowWarningIcon(bothEchoesReturned);

                        if (processedData.conditionals.artifact !== undefined) {
                            updateConditionalField(
                                SETTING_KEYS.artefacts,
                                processedData.conditionals.artifact
                            );
                        }

                        if (
                            processedData.conditionals.fieldStrength !==
                            undefined
                        ) {
                            updateConditionalField(
                                SETTING_KEYS.fieldStrength,
                                processedData.conditionals.fieldStrength
                            );
                        }

                        if (
                            processedData.conditionals.spatialGradient !==
                            undefined
                        ) {
                            updateConditionalField(
                                SETTING_KEYS.spatialGradient,
                                processedData.conditionals.spatialGradient
                            );
                        }

                        if (
                            processedData.conditionals.temperature !== undefined
                        ) {
                            updateConditionalField(
                                SETTING_KEYS.temp,
                                processedData.conditionals.temperature
                            );
                        }

                        if (processedData.conditionals.sar !== undefined) {
                            updateConditionalField(
                                SETTING_KEYS.sar,
                                processedData.conditionals.sar
                            );
                        }

                        if (
                            processedData.conditionals.immediateScanning !==
                            undefined
                        ) {
                            updateConditionalField(SETTING_KEYS.waitingPeriod, {
                                immediateScan:
                                    processedData.conditionals.immediateScanning
                                        .immediateScan,
                            });
                        }

                        setAutofilledFields(processedData.autofilledFields);
                    }
                } catch (error: any) {
                    setExtractionError(error);
                } finally {
                    setIsExtracting(false);
                }
            }
        };

        extractData();
    }, [fileName]);

    // Reset extraction error and autofilled fields when the source changes
    useEffect(() => {
        setExtractionError('');
        setAutofilledFields({});
    }, [fileName]);

    const handleImage = (image: any) => {
        if (image) {
            setImageError(false);
            setImageName(image.name);
            setImage(image);
        }
    };

    type SaveState = 'draft' | 'versioned' | 'edits';

    const save = async (saveState: SaveState) => {
        setIsSaving(true);
        setNewImplantError(false);
        setSaved(false);
        const updatedInfo: any = {
            ...info,
            [SETTING_KEYS.type]: info[SETTING_KEYS.type],
            [SETTING_KEYS.manufacturer]: info[SETTING_KEYS.manufacturer],
            [SETTING_KEYS.mriStatus]: parseInt(info[SETTING_KEYS.mriStatus]),
            [SETTING_KEYS.filename]: fileName,
            [SETTING_KEYS.image]: imageName,
        };

        let updRes;
        if (saveState === 'draft') {
            updRes = await draftImplant(updatedInfo, conditionals);
        } else if (saveState === 'versioned') {
            updRes = await updateImplant(updatedInfo, conditionals, true);
        } else if (saveState === 'edits') {
            updRes = await updateImplant(
                updatedInfo,
                conditionals,
                false,
                notifyApprovers
            );
        }

        if (updRes.status === 200) {
            if (fileName) {
                await completeQueuedImplant(fileName);
            }
        }
        if (updRes.status === 200 && image !== undefined && image !== null) {
            await uploadImplantImage(image, updRes.data);
        }
        if (updRes.status !== 200) {
            setReturnedError(updRes.data);
            setIsSaving(false);
        } else {
            setIsSaving(false);
            setSaved(true);
        }
    };

    const addNewImplant = async (isDraft: boolean) => {
        setIsSaving(true);
        setSaved(false);

        if (isEnterCustomManufacturer) {
            const res = await addManufacturer(info[SETTING_KEYS.manufacturer]);
            if (res.status !== 200) {
                setIsSaving(false);
                setNewImplantError(true);
                setReturnedError(res.data.error);
                setSaved(false);
                return;
            }
        }
        const res = await addImplant(
            {
                ...info,
                [SETTING_KEYS.type]: info[SETTING_KEYS.type],
                [SETTING_KEYS.manufacturer]: info[SETTING_KEYS.manufacturer],
                [SETTING_KEYS.mriStatus]: parseInt(
                    info[SETTING_KEYS.mriStatus]
                ),
                [SETTING_KEYS.editStatus]: isDraft ? 1 : 2,
                [SETTING_KEYS.filename]: fileName,
                [SETTING_KEYS.image]: imageName,
            },
            conditionals
        );

        if (res.status === 200) {
            if (fileName) {
                await completeQueuedImplant(fileName);
            }
        }

        if (res.status === 200 && image !== undefined && image !== null) {
            await uploadImplantImage(image, res.data);
        }
        setIsSaving(false);
        if (res.status !== 200) {
            setNewImplantError(true);
            setReturnedError(res.data.error);
            setSaved(false);
        } else {
            setSaved(true);
        }
    };

    const handleOnSave = (saveState: SaveState) => {
        let errorFlag = false;
        let errorConStr = '';

        if (info[SETTING_KEYS.model] === '') {
            errorFlag = true;
            if (!errorConStr.includes('Model')) {
                errorConStr = errorConStr + ' Model';
            }
        }

        if (info[SETTING_KEYS.source] === '') {
            errorFlag = true;
            if (!errorConStr.includes('Source')) {
                errorConStr = errorConStr + ' Source';
            }
        }

        if (info[SETTING_KEYS.category] === '') {
            errorFlag = true;
            if (!errorConStr.includes('Category')) {
                errorConStr = errorConStr + ' Category';
            }
        }

        if (
            !info[SETTING_KEYS.manufacturer] ||
            info[SETTING_KEYS.manufacturer].trim() === ''
        ) {
            errorFlag = true;
            if (!errorConStr.includes('Manufacturer')) {
                errorConStr = errorConStr + ' Manufacturer';
            }
        }

        if (
            isEnterCustomManufacturer &&
            info[SETTING_KEYS.manufacturer] === ''
        ) {
            errorFlag = true;
            if (!errorConStr.includes('Manufacturer')) {
                errorConStr = errorConStr + ' Manufacturer';
            }
        }

        if (!info[SETTING_KEYS.type] || info[SETTING_KEYS.type].trim() === '') {
            errorFlag = true;
            if (!errorConStr.includes('Type')) {
                errorConStr = errorConStr + 'Type';
            }
        }

        if (info[SETTING_KEYS.mriStatus] === 2) {
            if (conditionals[SETTING_KEYS.sar].length !== 0) {
                conditionals[SETTING_KEYS.sar].forEach((s: any) => {
                    if (s.value === '') {
                        errorFlag = true;
                        if (!errorConStr.includes('SAR')) {
                            errorConStr = errorConStr + ' SAR';
                        }
                    }
                });
            }

            if (
                conditionals[SETTING_KEYS.spatialGradient].gradients.length !==
                0
            ) {
                conditionals[SETTING_KEYS.spatialGradient].gradients.forEach(
                    (sp: any) => {
                        if (
                            sp.value === undefined ||
                            sp.value === null ||
                            sp.value === 0
                        ) {
                            errorFlag = true;
                            if (!errorConStr.includes('SpatialGradient')) {
                                errorConStr = errorConStr + ' SpatialGradient';
                            }
                        }
                    }
                );
            }

            if (conditionals[SETTING_KEYS.temp].length !== 0) {
                conditionals[SETTING_KEYS.temp].forEach((t: any) => {
                    if (t.maxRise === '') {
                        errorFlag = true;
                        if (!errorConStr.includes('Temperature')) {
                            errorConStr = errorConStr + ' Temperature';
                        }
                    }
                });
            }

            if (conditionals[SETTING_KEYS.artefacts].length !== 0) {
                conditionals[SETTING_KEYS.artefacts].forEach((art: any) => {
                    if (art.distance === '') {
                        errorFlag = true;
                        if (!errorConStr.includes('Artefacts-Distance')) {
                            errorConStr = errorConStr + ' Artefacts-Distance';
                        }
                    }
                });
            }
        }

        if (fileName === undefined || fileName === null) {
            setUploadStatus('source-missing');
        }

        errorConStr = errorConStr.slice(1).replaceAll(' ', ', ');
        setIsError(errorFlag);
        setErrorCond(errorConStr);

        if (!errorFlag && !isNew) {
            save(saveState);
        } else if (!errorFlag && isNew) {
            addNewImplant(saveState === 'draft');
        }
    };

    useEffect(() => {
        if (saved && onSubmit) {
            onSubmit();
            setShowSavedMessage(true);
            const timer = setTimeout(() => {
                setShowSavedMessage(false);
            }, 3000);
            setSaved(false);
            return () => clearTimeout(timer);
        }
    }, [saved]);

    const itemsRef = useRef<Array<HTMLTextAreaElement | null>>([]);

    const updateInfoField = (fieldName: any, value: any) => {
        dispatchInfo({
            type: 'UPDATE',
            payload: { name: fieldName, value: value },
        });
    };

    const updateConditionalField = (fieldName: any, value: any) => {
        console.log(fieldName, value);
        dispatchConditionals({
            type: 'UPDATE',
            payload: { name: fieldName, value: value },
        });
    };

    const updateConditional = (e: any) => {
        updateConditionalField(e.target.name, e.target.value);

        e.target.style.height = 0;
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, conditionals.length);
        for (let i of itemsRef.current) {
            i.style.height = '' + i.scrollHeight + 'px';
        }
    }, [isLoading]);

    const handleFieldStrengthChange = (e: any) => {
        const { value, checked } = e.target;
        const fieldStrength = conditionals[SETTING_KEYS.fieldStrength];

        let updatedFieldStrength;
        if (checked) {
            updatedFieldStrength = [...fieldStrength, parseFloat(value)];
        } else {
            updatedFieldStrength = fieldStrength.filter(
                (i: any) => i !== parseFloat(value)
            );
        }

        updateConditionalField(
            SETTING_KEYS.fieldStrength,
            updatedFieldStrength
        );
    };

    const addCustomField = () => {
        dispatchInfo({ type: 'ADD_CUSTOM_FIELD' });
    };

    const updateCustomField = (index: number, field: any) => {
        dispatchInfo({
            type: 'UPDATE_CUSTOM_FIELD',
            payload: { index, field },
        });
    };

    const deleteCustomField = (index: number) => {
        const updatedCustomFields = [...info[SETTING_KEYS.customFields]];
        updatedCustomFields.splice(index, 1);
        dispatchInfo({
            type: 'UPDATE',
            payload: {
                name: SETTING_KEYS.customFields,
                value: updatedCustomFields,
            },
        });
    };

    const updateType = (type: string) => {
        updateInfoField(SETTING_KEYS.type, type);
    };

    const resetHighlights = (fieldNames: AutofilledFieldsKeys[]) => {
        setAutofilledFields((prev: AutofilledFieldsType) => {
            const newFields = { ...prev };
            fieldNames.forEach((fieldName) => {
                if (newFields[fieldName]) {
                    newFields[fieldName] = false;
                }
            });
            return newFields;
        });
    };

    return (
        <>
            <div
                className={`${styles.implant} ${!isNew ? styles.preview : ''}`}
            >
                {extractionError && (
                    <Alert
                        message={extractionError}
                        onClose={() => setExtractionError('')}
                    />
                )}
                {isExtracting ? (
                    <div className={styles['loading-wrapper']}>
                        <LoadingRing size="large" />
                    </div>
                ) : (
                    <>
                        <div
                            className={`${styles.flex} ${
                                !isNew ? 'preview' : ''
                            }`}
                        >
                            <div
                                className={`${styles.conditionals} ${
                                    !isNew ? 'preview' : ''
                                } ${styles.backgroundContainer}`}
                            >
                                {!userContext.orRights([
                                    RIGHTS_KEYS.manageImplant,
                                ]) && (
                                    <>
                                        <GeneralInfo>
                                            <label className={styles.infoLabel}>
                                                GUDID Device ID
                                            </label>
                                            <div
                                                className={
                                                    styles['inline-inputs']
                                                }
                                            >
                                                <FormInput
                                                    type="text"
                                                    name={
                                                        SETTING_KEYS.gudidDeviceId
                                                    }
                                                    defaultValue={
                                                        info[
                                                            SETTING_KEYS
                                                                .gudidDeviceId
                                                        ] || ''
                                                    }
                                                    onChange={(e) =>
                                                        updateInfoField(
                                                            SETTING_KEYS.gudidDeviceId,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            {/* TODO: Brand not being changed. Server returns BAD REQUEST */}
                                            <Brand
                                                manufacturers={manufacturers}
                                                isCustomManufacturer={
                                                    isEnterCustomManufacturer
                                                }
                                                info={
                                                    info[
                                                        SETTING_KEYS
                                                            .manufacturer
                                                    ]
                                                }
                                                manufacturerString={
                                                    SETTING_KEYS.manufacturer
                                                }
                                                onUpdateInfoField={
                                                    updateInfoField
                                                }
                                                toggleCustomManufacturer={
                                                    setIsEnterCustomManufacturer
                                                }
                                            />

                                            <Region
                                                regions={regions}
                                                info={info}
                                                regionString={
                                                    SETTING_KEYS.region
                                                }
                                                onRegionChange={
                                                    handleRegionChange
                                                }
                                            />

                                            <Model
                                                modelString={SETTING_KEYS.model}
                                                info={info}
                                                onUpdateInfoField={
                                                    updateInfoField
                                                }
                                            />

                                            <Category
                                                isStent={
                                                    info[
                                                        SETTING_KEYS.category
                                                    ] === 'Stent'
                                                        ? true
                                                        : false
                                                }
                                                info={info}
                                                onActivePassiveToggle={
                                                    handleActivePassiveChange
                                                }
                                                onUpdateInfoField={
                                                    updateInfoField
                                                }
                                            />
                                        </GeneralInfo>
                                    </>
                                )}

                                <div className={styles['inline-inputs']}>
                                    <TypeSearchField
                                        types={types}
                                        updateType={updateType}
                                        currentValue={info[SETTING_KEYS.type]}
                                    />
                                </div>
                                <>
                                    <label className={styles.infoLabel}>
                                        Body placement
                                    </label>
                                    <input
                                        name={SETTING_KEYS.bodyPlacement}
                                        value={
                                            Array.isArray(
                                                info[SETTING_KEYS.bodyPlacement]
                                            )
                                                ? info[
                                                      SETTING_KEYS.bodyPlacement
                                                  ].join(',')
                                                : info[
                                                      SETTING_KEYS.bodyPlacement
                                                  ]
                                        }
                                        type="text"
                                        placeholder="Enter body placement"
                                        onChange={(e) =>
                                            updateInfoField(
                                                SETTING_KEYS.bodyPlacement,
                                                e.target.value.split(',')
                                            )
                                        }
                                    />
                                </>

                                <MriStatus
                                    mriStatusString={SETTING_KEYS.mriStatus}
                                    info={info}
                                    style={
                                        autofilledFields.status
                                            ? styles.autofilled
                                            : {}
                                    }
                                    onResetHighlights={resetHighlights}
                                    onUpdateInfoField={updateInfoField}
                                />

                                <label className={styles.infoLabel}>
                                    Commercial Distribution End Date
                                </label>
                                <input
                                    name={SETTING_KEYS.distEndDate}
                                    type="date"
                                    value={
                                        info[SETTING_KEYS.distEndDate] !== '' &&
                                        info[SETTING_KEYS.distEndDate] !==
                                            null &&
                                        info[SETTING_KEYS.distEndDate] !==
                                            undefined
                                            ? new Date(
                                                  info[SETTING_KEYS.distEndDate]
                                              )
                                                  .toISOString()
                                                  .split('T')[0]
                                            : ''
                                    }
                                    onChange={(e) =>
                                        updateInfoField(
                                            SETTING_KEYS.distEndDate,
                                            e.target.value
                                        )
                                    }
                                />

                                <label className={styles.infoLabel}>
                                    Catalog/Model numbers *
                                </label>
                                <CatalogNumbers />

                                <label className={styles.infoLabel}>
                                    GMDN definition
                                </label>
                                <textarea
                                    name={SETTING_KEYS.gmdnDefinition}
                                    placeholder="Provide GMDN definition"
                                    value={info[SETTING_KEYS.gmdnDefinition]}
                                    onChange={(e) =>
                                        updateInfoField(
                                            SETTING_KEYS.gmdnDefinition,
                                            e.target.value
                                        )
                                    }
                                />
                            </div>

                            <div className={styles.additionalInfo}>
                                <ImplantSource
                                    info={info}
                                    fileName={fileName}
                                    uploadStatus={uploadStatus}
                                    onSetModal={setIsModalOpen}
                                    onUpdateInfoField={updateInfoField}
                                    source={SETTING_KEYS.source}
                                    documentDate={SETTING_KEYS.docDate}
                                />

                                {uploadStatus === 'source-error' && (
                                    <Alert
                                        message={
                                            'An error occured in uploading the source file. Please check the link and upload again if necessary.'
                                        }
                                        onClose={() =>
                                            setUploadStatus('source-done')
                                        }
                                    />
                                )}
                                <div className={styles.backgroundContainer}>
                                    <h3>Image</h3>
                                    <DragAndDrop
                                        noBg={true}
                                        dropHandler={handleImage}
                                        useContainerStyle={true}
                                    >
                                        <ImplantImage
                                            imageError={imageError}
                                            imageName={imageName}
                                        />
                                    </DragAndDrop>
                                </div>
                                <AdditionalInfo
                                    conditionals={conditionals}
                                    info={info}
                                    onUpdateConditionalField={
                                        updateConditionalField
                                    }
                                    onUpdateInfoField={updateInfoField}
                                />
                            </div>
                        </div>

                        <Modal
                            isOpen={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                        >
                            <SelectSource
                                isPreview={true}
                                onSelect={() => setIsModalOpen(false)}
                            />
                        </Modal>

                        {parseInt(info[SETTING_KEYS.mriStatus]) === 2 && (
                            <Conditional
                                settings={settings}
                                conditionals={conditionals}
                                fieldStrengths={fieldStrengths}
                                autofilledFields={autofilledFields}
                                showWarningIcon={showWarningIcon}
                                itemsRef={itemsRef}
                                onSetShowWarningIcon={setShowWarningIcon}
                                onSetAutoFilledFields={setAutofilledFields}
                                onFieldStrengthChange={
                                    handleFieldStrengthChange
                                }
                                onUpdateConditionalField={
                                    updateConditionalField
                                }
                                onResetHighlights={resetHighlights}
                                onUpdateConditional={updateConditional}
                            />
                        )}
                        <CustomFields
                            info={info}
                            onAddField={addCustomField}
                            onUpdateField={updateCustomField}
                            onDeleteField={deleteCustomField}
                        />
                    </>
                )}{' '}
            </div>
            <div className={styles.save}>
                <Button
                    type={ButtonType.primary}
                    onClick={() => handleOnSave('draft')}
                    // disabled={
                    //     extractionError !== '' ||
                    //     showWarningIcon ||
                    //     (uploadStatus !== 'source-done' && !usesExistingFile)
                    // }
                >
                    Save draft
                </Button>
                {!isNew && (
                    <Button
                        type={ButtonType.submit}
                        onClick={() => handleOnSave('edits')}
                        disabled={
                            extractionError !== '' ||
                            showWarningIcon ||
                            (uploadStatus !== 'source-done' &&
                                !usesExistingFile &&
                                isNew)
                        }
                    >
                        Save Edits
                    </Button>
                )}
                <Button
                    type={ButtonType.submit}
                    onClick={() => handleOnSave('versioned')}
                    disabled={
                        extractionError !== '' ||
                        showWarningIcon ||
                        (uploadStatus !== 'source-done' &&
                            !usesExistingFile &&
                            isNew)
                    }
                >
                    {isNew ? 'Save and Complete' : 'Save as New Version'}
                </Button>
            </div>
            {!isNew && (
                <SelectItem
                    labelled={true}
                    type="checkbox"
                    value="Notify Approvers of Update (only applicable for 'Save Edits')"
                    onChange={(value, checked) => {
                        setNotifyApprovers(checked);
                    }}
                />
            )}
            {fileError && (
                <div style={{ color: 'red' }}>
                    Please provide the IFU or source of the data
                </div>
            )}

            {isError && (
                <div>
                    Please check empty fields: {errorCond}. Delete empty or fill
                    them before saving.
                </div>
            )}
            {isSaving && <div>Saving...</div>}
            {showSavedMessage && <div>Implant has been Saved</div>}
            {returnedError && <div>{returnedError}</div>}
            {isNew && newImplantError && <div>{returnedError}</div>}
        </>
    );
};

export default EditImplant;
