import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { setPassword } from '../../services/apiService';
import Button, { ButtonType } from './../general-components/Button';
import styles from './ResetPassword.module.css';
import FormInput from '../general-components/FormInput';
import {
    passwordValidate,
    repeatPasswordValidate,
} from '../../services/formValidationService';
import SignupLayout from '../ui-components/SignupLayout';

const ResetPassword: React.FC = () => {
    const [password, setCurrPassword] = useState<string>('');
    const [repeat, setRepeat] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [showConfirm, setShowConfirm] = useState(false);

    let { id } = useParams<{ id: string }>();

    const setNewPassword = () => {
        const pw = passwordValidate(password);
        const rpw = repeatPasswordValidate(password, repeat);
        if (!pw.valid) {
            setError(pw.message);
        } else if (!rpw.valid) {
            setError(rpw.message);
        } else {
            setPassword(password, id);
            setShowConfirm(true);
        }
    };

    return (
        <SignupLayout>
            {showConfirm && (
                <React.Fragment>
                    <div className={styles.info}>
                        <h3>Password has been updated</h3>
                    </div>
                    <form className={styles['login-form']}>
                        <Button
                            className={styles.button}
                            type={ButtonType.primary}
                            onClick={() => {
                                window.location.href = '/login';
                            }}
                        >
                            Return to login page
                        </Button>
                    </form>
                </React.Fragment>
            )}
            {!showConfirm && (
                <React.Fragment>
                    <div className={styles.info}>
                        <h3>Change password</h3>
                    </div>
                    <form className={styles.form}>
                        <FormInput
                            label="Password"
                            placeholder="Enter your password"
                            onChange={({ target }) => {
                                setCurrPassword(
                                    (target as HTMLInputElement).value
                                );
                            }}
                            type="password"
                            errorMsg={error}
                        />
                        <FormInput
                            label="Repeat Password"
                            placeholder="Enter your password"
                            onChange={({ target }) => {
                                setRepeat((target as HTMLInputElement).value);
                            }}
                            type="password"
                            errorMsg={error}
                        />
                        <Button
                            className={styles.button}
                            type={ButtonType.submit}
                            onClick={() => setNewPassword()}
                        >
                            Set New Password
                        </Button>
                    </form>
                </React.Fragment>
            )}
        </SignupLayout>
    );
};

export default ResetPassword;
